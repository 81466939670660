import { format, parseISO, formatISO } from 'date-fns'

const EpisodeHelpers = {
    methods: {
        getDateRange(episode) {
            if (!episode) return ''
            const startDate = episode.startDate
                ? format(parseISO(episode.startDate), 'dd/MM/yyyy')
                : 'N/A'
            const endDate = episode.endDate
                ? format(parseISO(episode.endDate), 'dd/MM/yyyy')
                : 'Present'
            return `${startDate} - ${endDate}`
        },
        getEpisodeStatus(episode) {
            if (!episode) return ''
            const status = this.episodeStatuses.find((es) => es.value === episode.status)
            return status ? status.text : ''
        },
        getSiteSection(episode) {
            if (!episode) return ''

            const siteSection = this.siteSections
                ? this.siteSections.find((ss) => ss.value === episode.locationProgramId)
                : null
            return siteSection ? siteSection.text : ''
        },
        getType(episode) {
            if (!episode) return ''

            const type = this.types ? this.types.find((ss) => ss.value === episode.type) : null
            return type ? type.text : ''
        },
        getWorker(episode) {
            if (!episode) return ''

            const worker = episode.worker
            return worker && worker.firstName ? `${worker.firstName} ${worker.lastName}` : ''
        },
        getStatusColour(episode) {
            if (!episode || typeof episode !== 'object') return ''

            let statusColour
            switch (episode.status) {
                case 'OPEN':
                    statusColour = 'text-green'
                    break
                case 'PENDING':
                    statusColour = 'text-orange'
                    break
                case 'SUITABLE':
                    statusColour = 'text-yellow-dark'
                    break
                case 'UNSUITABLE':
                    statusColour = 'text-red'
                    break
                case 'CLOSED':
                    statusColour = 'text-red'
                    break
                case 'REFERRED':
                    statusColour = 'text-light-green'
                    break
                case 'DID_NOT_COMMENCE':
                    statusColour = 'text-red'
                    break
                default:
                    statusColour = 'text-grey-1'
            }
            return statusColour
        },
        isEpisodeLocked(episode) {
            return (
                !this.loggedInUser ||
                !this.loggedInUser.locationProgramIds.includes(episode.locationProgramId)
            )
        }
    }
}

const EpisodeSaveHelpers = {
    methods: {
        setEpisodeStatus(episode, useDefault, id) {
            if (!episode) return
            if (useDefault) {
                episode.status = 'PENDING'
                episode.dateReferred = formatISO(new Date())
                episode.setReferredBy = id
                return
            }

            switch (episode.type) {
                case 'NON_RESIDENTIAL':
                case 'RESIDENTIAL':
                case 'DEADLY_DREAMING':
                default:
                    episode.setReferredBy = id
                    episode.dateReferred = formatISO(new Date())
                    episode.status = 'REFERRED'
            }
        },
        setEpisodeStartDate(episode) {
            if (!episode) return

            // if episode type is QUINN, we want to set the startDate as today, otherwise it's null
            switch (episode.type) {
                case 'STUART':
                    episode.startDate = format(new Date(), 'yyyy-MM-dd')
                    break
                default:
                    episode.startDate = null
                    break
            }
        }
    }
}

export { EpisodeHelpers, EpisodeSaveHelpers }
