import gql from 'graphql-tag'

const GET_STAFF_USERS = gql`
    query getUsers {
        users: ted2_users(where: {
            account_type: {_neq: "student"},
            status: {_neq: "DEACTIVATED"}
        }) {
            user_id
            last_seen
            name
            first_name
            last_name
            auth0_id
        }
    }
`

export { GET_STAFF_USERS }
