<template>
    <v-form class="a-contact-form" ref="form" v-model="isValid">
        <div class="relative state-select-wrapper">
            <v-select
                attach=".state-select-wrapper"
                v-model="locationId"
                label="State"
                :items="sortItems(locationItems)"
                clearable
            ></v-select>
        </div>
        <div class="relative location-program-select-wrapper">
            <v-select
                attach=".location-program-select-wrapper"
                v-model="episodeForm.locationProgramId"
                label="Site - Section"
                :items="sortItems(siteSectionsByLocation)"
                :rules="[required]"
                @change="locationProgramChangeHandler"
            ></v-select>
        </div>
        <div class="relative type-select-wrapper">
            <v-select
                attach=".type-select-wrapper"
                v-model="episodeForm.type"
                label="Episode Type"
                :items="typesByLocationProgram"
                :rules="[required]"
            ></v-select>
        </div>
        <div class="relative worker-select-wrapper">
            <v-autocomplete
                attach=".worker-select-wrapper"
                :items="workersByLocationProgram"
                :filter="customFilter"
                color="white"
                label="Worker"
                v-model="episodeForm.userId"
                :rules="[required]"
            ></v-autocomplete>
        </div>
    </v-form>
</template>

<script>
import {mapState} from 'vuex'
import {FormRules} from '@/components/shared/mixins/formMixins'
import {ClientHelpers} from '@/components/shared/mixins/clientMixins'
import GET_LOCATION_PROGRAM_TYPES from '@/graphql/queries/getLocationProgramTypes.gql'
import camelcaseKeys from 'camelcase-keys'
import sortItems from '@/components/shared/mixins/sortItems'

export default {
    name: 'Episode',
    mixins: [FormRules, ClientHelpers, sortItems],
    props: {
        value: {
            type: Object,
            default: () => {}
        },
        users: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            isValid: true,
            locationId: '',
            defaultEpisode: {
                status: '',
                type: '',
                userId: '',
                locationProgramId: ''
            },
            locationProgramTypes: [],
            typesByLocationProgram: []
        }
    },
    apollo: {
        locationProgramTypes: {
            query: GET_LOCATION_PROGRAM_TYPES,
            result({data: {locationProgramTypes}}) {
                this.locationProgramTypes = camelcaseKeys(locationProgramTypes || [], {deep: true})
            }
        }
    },
    methods: {
        customFilter(item, queryText, itemText) {
            const textOne = item.name.toLowerCase()
            const textTwo = item.abbr ? item.abbr.toLowerCase() : ''
            const searchText = queryText.toLowerCase()

            return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
        },
        validate() {
            this.isValid = this.$refs.form.validate()
            return this.isValid
        },
        locationChangeHandler() {
            this.episodeForm.locationProgramId = ''
            this.episodeForm.type = ''
            this.episodeForm.userId = ''
        },
        locationProgramChangeHandler() {
            this.episodeForm.type = ''
            this.episodeForm.userId = ''

            const { types } = this.siteSections.find(siteSection => this.episodeForm.locationProgramId === siteSection.value)
            if (!types) return
            const episodeTypes = this.episodeTypes.filter(episodeType => types.includes(episodeType.code))

            this.typesByLocationProgram = episodeTypes.map(episodeType => {
                return {
                    value: episodeType.code,
                    text: episodeType.name
                }
            })
        }
    },
    computed: {
        ...mapState({
            locationItems: state => state.app.locationItems,
            siteSections: state => state.app.siteSections,
            episodeTypes: state => state.app.episodeTypes
        }),
        siteSectionsByLocation() {
            if (!this.siteSections?.length) return []
            if (!this.locationId) return this.siteSections
            return this.siteSections.filter(
                siteSection => siteSection.locationId === this.locationId
            )
        },
        episodeForm: {
            get() {
                return this.value || this.defaultEpisode
            },
            set(episodeForm) {
                this.$emit('input', episodeForm)
            }
        },
        workersByLocationProgram() {
            if (!this.workers?.length) return []

            if (!this.episodeForm.locationProgramId) return this.workers

            return this.workers.filter(worker =>
                worker.locationProgramIds.includes(this.episodeForm.locationProgramId)
            )
        }
    }
}
</script>
