import snakecaseKeys from 'snakecase-keys'
import camelcaseKeys from 'camelcase-keys'
import { APP_API_V2, REST_API } from '@/utils/constants'

import { getInstance } from '@/auth'
import axiosInstance from '@/utils/axios'

export class LocationService {
    constructor() {
        this.locationUrl = `${APP_API_V2}/locations`
    }

    async getLocations(tenantId) {
        const { data } = await axiosInstance.get(
            `${this.locationUrl}?tenant_id=${tenantId}`,
            {
                headers: {
                    Authorization: `Bearer ${getInstance().token}`
                }
            }
        )
        return camelcaseKeys(data, { deep: true })
    }
}

export const locationService = new LocationService()
