import { format, parseISO } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'
import { DEFAULT_TIMEZONE } from '@/utils/constants'

const SharedMethods = {
    methods: {
        getFormattedDate(dueDate) {
            if (!dueDate) return ''
            // getting the current clients timezone, if we can't, default to sydney time
            const timezone = Intl.DateTimeFormat()?.resolvedOptions()?.timeZone ?? DEFAULT_TIMEZONE
            // first we check if we have any indication if it's a full iso datetime - check if 'T' is in the string
            const idx = dueDate.indexOf('T'),
                zIdx = dueDate.indexOf('Z')
            // adding 'Z' to the end of ths string if we don't have it already
            const dueDateCheck = idx > -1 && zIdx === -1 ? `${dueDate}Z` : dueDate

            const parsedDate = parseISO(dueDateCheck)
            return formatInTimeZone(parsedDate, timezone, 'dd/MM/yy')
        },
    }
}

const TableMethods = {
    methods: {
        async addNewRow(key, callback) {
            // we check to see if we have a row we are editing, if we do, save it
            if (!this.defaultNewObject) throw new Error('defaultNewObject is not defined, to use this mixin, define it on the component')
            if (this.activeRowId) await callback.call(this)

            this.sortBy = []
            this.sortDesc = [true]

            this.currentNewId = Math.floor(Math.random() * 1000000) + 5000000
            this.activeRowId = this.currentNewId
            const tempObj = {
                ...this.defaultNewObject
            }
            tempObj[key] = this.currentNewId
            tempObj.order = this.nextOrder
            this.tableDataArr.push(tempObj)
            this.expanded = [tempObj]
        },
    },
    computed: {
        nextOrder() {
            let next = 0
            this.tableDataArr.forEach((data) => {
                const currentOrder = Number(data.order)
                if (next < currentOrder) next = currentOrder
            })

            next++
            return next.toString()
        }
    }
}

export {
    SharedMethods,
    TableMethods
}
