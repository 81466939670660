import snakecaseKeys from 'snakecase-keys'
import camelcaseKeys from 'camelcase-keys'
import { APP_API_V2 } from '@/utils/constants'

import { getInstance } from '@/auth'
import axiosInstance from '@/utils/axios'

export class CheckinService {
    constructor() {
        this.checkinsUrl = `${APP_API_V2}/campus-checkins`
    }

    async addCheckin(data) {
        const { data: checkin } = await axiosInstance.post(
            `${this.checkinsUrl}`,
            snakecaseKeys(data)
        )
        return camelcaseKeys(checkin)
    }

    async updateCheckin(data) {
        const { locationProgram, ...rest } = data
        const { data: checkin } = await axiosInstance.patch(
            `${this.checkinsUrl}/${rest.checkinId}`,
            snakecaseKeys(rest),
            {
                headers: {
                    Authorization: `Bearer ${getInstance().token}`
                }
            }
        )
        return camelcaseKeys(checkin)
    }

    async deleteCheckin(checkinId) {
        const { data: checkin } = await axiosInstance.delete(`${this.checkinsUrl}/${checkinId}`, {
            headers: {
                Authorization: `Bearer ${getInstance().token}`
            }
        })
        return camelcaseKeys(checkin)
    }

    async getCheckins(locationProgramId, startDate, endDate) {
        let url = `${this.checkinsUrl}`
        if (locationProgramId) {
            url = `${url}?location_program=${locationProgramId}`
        }
        if (startDate) {
            url = `${url}&start_date=${startDate}`
        }
        if (endDate) {
            url = `${url}&end_date=${endDate}`
        }
        const { data: checkins } = await axiosInstance.get(url, {
            headers: {
                Authorization: `Bearer ${getInstance().token}`
            }
        })
        return camelcaseKeys(checkins)
    }
}

export const checkinService = new CheckinService()
