import { getOrCreateProvider } from '@/vue-apollo'
import GET_USER_BY_AUTH0_ID from '@/graphql/queries/getUserByAuth0Id.gql'
import camelcaseKeys from 'camelcase-keys'
import store from '@/store'
import { CS_AUTH0_CLAIM_ID } from '@/utils/constants'

/** Load user info from our db (hasura) using the user id fetched from auth0 */
export default async (authService) => {
    const apolloProvider = getOrCreateProvider()

    try {
        const tenantId = authService.user[CS_AUTH0_CLAIM_ID]?.tenant
        const { data } = await apolloProvider.defaultClient.query({
            query: GET_USER_BY_AUTH0_ID,
            variables: { auth0Id: authService.user.sub, tenantId }
        })

        const user = data.users?.length && data.users[0]
        if (!user) {
            throw 'Cannot find user.'
        }
        await store.dispatch('setLoggedInUser', camelcaseKeys(user, { deep: true }))
        authService.isUserInfoLoaded = true
    } catch (e) {
        authService.error = e
        authService.isUserInfoLoaded = false
    }
}
