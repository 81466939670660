const PhoneHelper = {
    parse(phoneString) {
        if (typeof phoneString !== 'string') return phoneString;
        const numberParts = phoneString ? phoneString?.split(' ') : null
        if (numberParts) {
            if (isNaN(numberParts[0])) {
                return {
                    countryCode: numberParts[0],
                    phone: numberParts.slice(1).join(' ')
                }
            } else {
                return {
                    countryCode: 'AU',
                    phone: phoneString
                }
            }
        } else {
            return {
                countryCode: 'AU',
                phone: ''
            }
        }
    }
}

export default PhoneHelper
