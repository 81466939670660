<template>
    <div class="search">
        <v-autocomplete
            :key="'search-input'"
            outlined
            dense
            single-line
            ref="searchComboBox"
            data-cy="searchClients"
            prepend-inner-icon="search"
            v-model="selection"
            item-text="name"
            item-value="clientId"
            hide-details
            :loading="loading"
            :items="computedClients"
            :search-input.sync="searchText"
            :hide-no-data="hideNoData"
            :menu-props="searchMenuProps"
            :filter="(v) => v"
            :rules="rules"
            @change="selectClientHandler"
        >
            <template v-slot:no-data>
                <v-list-item class="flex items-center">
                    <div class="text-xs text-grey-1">Client not found.</div>
                </v-list-item>
            </template>
            <template v-slot:item="{ item }">
                <v-list-item
                    :disabled="enableToConnect(item)"
                    :class="{ 'bg-grey-6': enableToConnect(item) }"
                    @click="selectClient(item)"
                >
                    <v-list-item-content>
                        <v-list-item-title class="flex items-center">
                            <div class="mr-3 text-grey-2 font-bold text-xs">
                                ID {{ item.clientId }}
                            </div>
                            <div class="mr-3 text-grey-1 font-normal">
                                {{ getNameForClient(item) }}
                            </div>
                            <div class="mr-3 text-grey-2 font-bold text-xs">
                                YR OF BIRTH {{ getYear(item.dob) }}
                            </div>
                            <div
                                class="text-grey-1 font-normal"
                                v-if="item.hasStudent && hasStudent"
                            >
                                YP Linked: {{ item.user.status }}
                            </div>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
            <template v-slot:append-item>
                <slot name="append-item"></slot>
            </template>
            <template v-slot:label>
                <slot name="label-inner"></slot>
            </template>
        </v-autocomplete>
    </div>
</template>

<script>
import { parseISO, format, isValid } from 'date-fns'
import { mapState } from 'vuex'
import { ref, watch, reactive, toRefs } from '@vue/composition-api'
import { ClientHelpers } from '@/components/shared/mixins/clientMixins'
import { StuniStatus } from '@/components/shared/mixins/stuniMixin'
import { clientService } from '@/services/client.service'
import { getNameForClient } from '@/helpers/client.display'

export default {
    name: 'Search',
    mixins: [ClientHelpers],
    props: {
        search: {
            type: String,
            default: ''
        },
        showMore: {
            type: Boolean,
            default: false
        },
        rules: {
            type: Array,
            default: () => []
        },
        clearText: {
            type: Boolean,
            default: true
        },
        hasStudent: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            selection: null,
            currentClient: null,
            skipFiltered: false,
            // clientList: [],
            searchText: '',
            searchTimeout: null,
            nonce: 1,
            searchMenuProps: {
                closeOnClick: false,
                closeOnContentClick: true
            }
        }
    },
    mounted() {
        this.searchText = this.search
    },
    methods: {
        selectClient(item) {
            this.$refs.searchComboBox.setValue(item.clientId)
        },
        enableToConnect(item) {
            if (!item.hasStudent || item.user.status === StuniStatus.INVITED || !this.hasStudent)
                return false

            return true
        },
        selectClientHandler(clientId) {
            this.$emit('client-id-changed', clientId)
            this.$nextTick(() => {
                this.clearSearch(this.clearText)
            })
        },
        clearSearch(clearText) {
            if (clearText) {
                this.selection = null
                this.searchText = null
                this.textChangedHandler()
            }
        },
        getYear(date) {
            const parsedDate = parseISO(date, 'yyyy-MM-dd', new Date())
            if (!isValid(parsedDate)) return 'None recorded'

            return format(parsedDate, 'yyyy')
        },
        textChangedHandler() {
            this.$emit('update:search', this.searchText)
        }
    },
    computed: {
        ...mapState({
            clientId: (state) => state.app.clientId,
            loggedInUser: (state) => state.app.loggedInUser
        }),
        hideNoData() {
            return this.searchText == null || this.searchText === '' || this.loading
        },
        computedClients() {
            return this.clientList
        }
    },
    watch: {
        searchText() {
            let searchId = this.searchText ? Number(this.searchText) : null
            // need to set it to -1 so the other search params can work, setting to -1 means searchId is ignored
            this.skipFiltered = searchId != null && !isNaN(searchId) && searchId > 0
            if (this.searchTimeout) clearTimeout(this.searchTimeout)
            this.textChangedHandler()
        }
    },
    setup(props, { emit }) {
        const searchTimeout = ref(null)
        const state = reactive({
            clientList: [],
            page: null,
            pages: 1,
            loading: false,
            showMoreVal: false
        })

        const getMoreClients = async (loadMore) => {
            try {
                const {
                    clients,
                    page: newPage,
                    pages
                } = await clientService.searchClients(props.search, state.page)
                clients.forEach((client) => {
                    client.name = getNameForClient(client)
                    client.hasStudent = client.user ? true : false
                })
                if (loadMore) state.clientList = [...state.clientList, ...clients]
                else state.clientList = [...clients]
                state.showMoreVal = newPage < pages

                emit('update:showMore', state.showMoreVal)
                state.page = newPage
                state.pages = pages
            } catch (error) {
                console.error(error)
            } finally {
                state.loading = false
            }
        }

        watch(
            () => state.loading,
            (newLoad) => {
                console.log('====== loading', newLoad)
            }
        )
        watch(
            () => props.search,
            async () => {
                // state.clients = []
                if (searchTimeout.value) clearTimeout(searchTimeout.value)
                state.loading = true
                searchTimeout.value = setTimeout(async () => {
                    await getMoreClients()
                    searchTimeout.value = null
                }, 800)
            }
        )
        return {
            ...toRefs(state)
        }
    }
}
</script>
